import styled from "styled-components";

export const Container = styled.div`
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  margin-left: 20px;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px;
  position: relative;
  transition: all .2s ease-in-out;

  background-color: ${(props) => (props.isDragging ? 'rgba(10, 118, 73, 0.1)' : 'rgba(249, 249, 249, 0.5)')};
  padding-bottom: ${(props) => (props.isDragging ? '2em' : '15px')};
  padding-top: ${(props) => (props.isDragging ? '1em' : '0px')};
  border: 4px solid ;
  border-color: ${(props) => (props.isDragging ? 'none' : `${props => props.env_color}`)}; 
  box-shadow: ${(props) => (props.isDragging ? '3px 3px 4px #888888' : 'none')};
`;

export const MainButton = styled.button`
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 16px;
  border: 1px solid ${props => props.env_color};
  color: #ffffff;
  background-color: ${props => props.env_color};
  width: 260px;
  margin: 10px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  z-index: 2;
  &:hover {
    background-color: #ffffff;
    color: ${props => props.env_color};
    border: 1px solid ${props => props.env_color};
  }

  &:focus {
    outline: 0 !important;
  }
`;

export const SubmitButton = styled.button`
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 16px;
  border: 1px solid #343434d9;
  color: #ffffff;
  background-color: #343434d9;
  width: 260px;
  margin: 10px;
  z-index: 2;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    background-color: #ffffff;
    color: #343434d9;
    border: 1px solid #343434d9;
  }

  &:focus {
    outline: 0 !important;
  }
`;

export const P = styled.p`
  font-size: 30px;
  color: ${props => props.env_color};
  text-align: center;
  z-index: 2;
  margin-top: 20px;
  margin-bottom: 50px;
`;

export const Label = styled.label`
/*  background-color: rgba(0, 133, 155, 0.5);*/
  display: flex;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const UploadInput = styled.input`
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const FileName = styled.div`
  
  font-size: 16px;
  font-weight: 600;
  color: #343434d9;
  padding: 0px 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${props => props.env_color};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
